@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");


body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* user-select: none; */
    letter-spacing: 0.025em;

    /* font-family: "Space Grotesk", sans-serif; */
    font-family: monospace;
    /* font-family: "Courier Prime"; */
    /* font-family: "Inconsolata"; */



    font-optical-sizing: auto;
    font-style: normal;
}



:root {
    --box-shadow: inset 0 6px 10px -4px rgba(0, 0, 0, 0.25);
    background-color: rgba(229, 229, 229, 0.25);
}

.dark {
    --box-shadow: inset 0 6px 10px -4px rgba(255, 255, 255, 0.25);
    background-color: rgb(38 38 38);
}
